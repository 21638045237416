import {createApp} from 'vue'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import ar from 'element-plus/es/locale/lang/ar'

// const cors = require('cors');
// import App from './components/ShopO.vue'
import App from './components/App.vue'

createApp(App)
    .use(ElementPlus,{ locale: ar })
    // .use(cors({
    //     origin: 'http://localhost:10100'
    // }))
    .mount('#app')


