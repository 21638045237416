<template>
  <div class="login">

    <div style="text-align: center;">
      <img src="https://io.gsbaloot.com/game/logo.png" style="width: 20%;padding-top: 20px"/>
    </div>
    <div class="large-white-bold-text1">
      1 - عند الشحن احرص أن يكون عنوان محفظتك مطابقًا ولا يوجد خطأ.
    </div>
    <div class="large-white-bold-text2">
      2 - يجب أن يكون الرصيد مطابقا لتكلفه شراء الجواهر.
    </div>
    <div class="large-white-bold-text3">
      3 - لايمكن استخدام عنوان محفظة واحده لحسابين مختلفين .
    </div>

    <div class="gem-container">
      <img src="../assets/diamond-icon.png" alt="Diamond Icon" class="diamond-icon"/>
      <span class="white-text">X {{ product.quantity * 100 }}</span>
      <div class="quantity">
        <button @click="decreaseQuantity(product)" :disabled="product.quantity <= 1">-</button>
        <input type="number" v-model="product.quantity" min=1 :max="product.stock" placeholder="USDT"/>
        <button @click="increaseQuantity(product)">+</button>
      </div>
      <!-- 按钮容器 -->
      <div class="buttons-container">
        <el-button type="primary" @click="openWalletList(1)">إعادة شحن</el-button>
        <el-button type="success" @click="openWalletList(2)">سحب جواهر</el-button>
        <el-button type="warning" @click="openOrder()">السجل</el-button>
      </div>
    </div>


  </div>

  <el-dialog
      v-model="isTixian"
      :width="'90%'"
      @close="tixianClose">

    <!-- 自定义标题居中 -->
    <template #title>
      <div style="text-align: center; font-size: 20px; font-weight: bold;">
        سحب الأموال
      </div>
    </template>

      <el-row>
        <el-col class="player-info" style="text-align: right;">
          <div class="player-details">
            <p>
              <el-tag
                  :style="getStatusStyle2('USDT')"
                  disable-transitions>
                <div style="display: flex; align-items: center;">
                  <span style="font-size: 12px; font-weight: bold;">{{ player.playerDiamonds / 100 }}</span>
                  <span style="margin: 0 5px;">x</span>
                  <img :src="getProductyImageUrl('USDT')" alt="Price Icon" style="width: 20px; height: 20px;">
                </div>
              </el-tag>
              =
              <el-tag
                  :style="getStatusStyle2('101')"
                  disable-transitions>
                <div style="display: flex; align-items: center;">
                  <span style="font-size: 12px; font-weight: bold;">{{ player.playerDiamonds }}</span>
                  <span style="margin: 0 5px;">x</span>
                  <img :src="getProductyImageUrl('101')" alt="Price Icon" style="width: 20px; height: 20px;">
                </div>
              </el-tag>
              <strong dir="rtl">عدد الجوهر:</strong>
            </p>

            <p>
              <el-tag
                  :style="getStatusStyle2('USDT')"
                  disable-transitions>
                <div style="display: flex; align-items: center;">
                  <span style="font-size: 12px; font-weight: bold;">{{ product.quantity }}</span>
                  <span style="margin: 0 5px;">x</span>
                  <img :src="getProductyImageUrl('USDT')" alt="Price Icon" style="width: 20px; height: 20px;">
                </div>
              </el-tag>
              <strong dir="rtl">المبلغ المراد سحبه:</strong>
            </p>

            <p>
              <el-tag
                  :style="getStatusStyle2('USDT')"
                  disable-transitions>
                <div style="display: flex; align-items: center;">
                  <span
                      style="font-size: 12px; font-weight: bold;">{{ product.quantity - product.quantity * 0.03 }}</span>
                  <span style="margin: 0 5px;">x</span>
                  <img :src="getProductyImageUrl('USDT')" alt="Price Icon" style="width: 20px; height: 20px;">
                </div>
              </el-tag>
              <strong dir="rtl">المبلغ بعد خصم الضريبه:</strong>
            </p>

            <p>
              <el-tag
                  :style="getStatusStyle2('USDT')"
                  disable-transitions>
                <div style="display: flex; align-items: center;">
                  <span style="font-size: 12px; font-weight: bold;">{{ product.quantity * 0.03 }}</span>
                  <span style="margin: 0 5px;">x</span>
                  <img :src="getProductyImageUrl('USDT')" alt="Price Icon" style="width: 20px; height: 20px;">
                </div>
              </el-tag>
              <strong dir="rtl">ضريبه التحويل (٣٪):</strong>
            </p>

            <p>
              <span>{{this.selectWallet}}</span>
              <strong dir="rtl">عنوان المحفظه:</strong>
            </p>
            <el-divider></el-divider>
            <p style="font-weight: bold; color: red;">الوقت المتوقع أن يتم الوصول خلال 2-5 دقائق</p>
            <el-divider></el-divider>
            <!-- 使用flexbox让按钮居中 -->
            <div class="button-container">
              <el-button type="primary" @click="sure2()">تاكيد</el-button>
            </div>

          </div>
        </el-col>
      </el-row>
  </el-dialog>

  <el-dialog
      v-model="isChongzhi"
      :before-close="handleClose"
      :width="'90%'"
      class="custom-dialog"
  >
    <el-card :body-style="{ padding: '20px' }" class="card">
      <el-row>
        <el-col :span="6" class="avatar-column">
          <img :src="getPlayerAvatarUrl(this.player.playerAvatar)" alt="Player Avatar" class="wallet-image"/>
        </el-col>
        <el-col :span="18" class="player-info" style="text-align: right;">
          <div class="player-details">
            <p><strong>رقم العضويه :</strong> {{ player.playerId }}</p>
            <p><strong>الأسم المستعار ：</strong> {{ player.playerNickname }}</p>
            <p><strong>الجواهر:</strong> {{ player.playerDiamonds }}</p>
            <p><strong>المحفظه </strong> {{ this.selectWallet }}</p>
          </div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="24" style="text-align: right;">
          <span>{{ product.quantity }} USDT = {{ product.quantity * 100 }} جواهر</span>
          <img
              :src="product.image"
              style="width: 13px; height: 13px; border-radius: 50%; cursor: pointer; "
          />
          <p>{{ product.quantity }} USDT = المبلغ </p>
          <p>رقم الطلبيه</p>
          <p>{{ this.order }}</p>
        </el-col>
      </el-row>
      <el-divider>عنوان المحفظه</el-divider>


      <el-col :span="6">
        <img :src="this.walletAddressUrl" alt="Player Avatar" class="wallet-address-image"/>
        <div style="margin-top: 10px; display: flex; align-items: center;">
          <span>{{ this.walletAddress }}</span>
        </div>
        <el-button type="primary" @click="copyWalletAddress">نسخ</el-button>
        <!--        <el-button type="primary" @click="copyWalletAddress" style="margin-top: 10px;">Copy</el-button>-->
      </el-col>
      <el-row>
        <el-col :span="24">
          <div @click="isWalletCollapsed = !isWalletCollapsed" class="wallet-toggle">
          <span class="arrow-icon">
            <i :class="isWalletCollapsed ? 'el-icon-arrow-right' : 'el-icon-arrow-down'"></i>
          </span>
          </div>
        </el-col>
      </el-row>

      <el-divider>اختار التطبيق</el-divider>
      <el-row>
        <el-col :span="24">
          <div v-show="!isWalletCollapsed" class="wallet-images">
            <div
                v-for="(app, index) in appUrl"
                :key="index"
                class="wallet-item"
                :class="{'selected': selectWallet === app.name}"
                @click="openWallet(app.name)"
            >
              <img :src="app.url" alt="App" class="wallet-image"/>
              <span>{{ app.name }}</span>
            </div>
          </div>
        </el-col>
      </el-row>

    </el-card>
  </el-dialog>

  <el-dialog
      v-model="isWalletList"
      width="300px"
      :show-close="false"
      @close="resetDialog">

    <template #title>
      <div style="text-align: center; color: red;">
        {{ tips }}
      </div>
    </template>

    <el-input
        v-if="isEditing"
        v-model="newWalletAddress"
        placeholder="إضافة عنوان لمحفظة جديدة"
        clearable
    >
    </el-input>
    <el-select
        v-if="!isEditing"
        v-model="selectWallet"
        placeholder="اختار عنوان المحفظه"
        @change="onWalletSelect"
        :style="{ maxHeight: '300px', overflowY: 'auto' }"
    >
      <el-option
          v-for="address in player.walletAddress"
          :key="address"
          :label="address"
          :value="address"
      ></el-option>
      <el-option
          label="اضف عنوان محفظتك "
          value="add_wallet"
          @click="addWallet"
          style="color: #4a4ac8;"
      ></el-option>
    </el-select>
    <div v-if="isEditing" class="add-wallet-actions">
      <el-button type="primary" @click="addNewWallet">إضافة</el-button>
    </div>

    <div v-if="!isEditing" class="add-wallet-actions">
      <el-button type="primary" @click="sure()">تاكيد</el-button>
    </div>
  </el-dialog>

  <el-dialog
      v-model="isOrderList"
      width="100%"
      @close="orderClose">
    <el-table
        :data="orderList"
        style="width: 100%; table-layout: fixed; word-wrap: break-word;">
      <el-table-column
          prop="price"
          label="السعر"
          :style="{ width: '35%' }">
        <template #default="{ row }">
          <el-tag
              :style="getStatusStyle2(row.payType)"
              disable-transitions>
            <div style="display: flex; align-items: center;">
              <!-- 将数字加大并加粗 -->
              <span style="font-size: 11px; font-weight: bold;">{{ row.price }}</span>
              <span style="margin: 0 5px;">x</span>
              <img :src="getProductyImageUrl(row.payType)" alt="Price Icon" style="width: 20px; height: 20px;">
            </div>
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
          prop="product_num"
          label="المنتج"
          :style="{ width: '35%' }">
        <template #default="{ row }">
          <el-tag
              :style="getStatusStyle2(row.productId)"
              disable-transitions>
            <div style="display: flex; align-items: center;">
              <!-- 将数字加大并加粗 -->
              <span style="font-size: 11px; font-weight: bold;">{{ row.productNum }}</span>
              <span style="margin: 0 5px;">x</span>
              <img :src="getProductyImageUrl(row.productId)" alt="Product Icon" style="width: 20px; height: 20px;">
            </div>
          </el-tag>
        </template>
      </el-table-column>


      <el-table-column
          prop="status"
          label=" حالة"
          :style="{ width: '25%' }">
        <template #default="{ row }">
          <el-tag
              :style="getStatusStyle(row)"
              disable-transitions>
            <span style="font-size: 11px; font-weight: bold;">{{ formatStatus(row) }}</span>
          </el-tag>
        </template>
      </el-table-column>


    </el-table>
    <div class="dialog-footer">
      <el-pagination
          @current-change="handlePageChange"
          :current-page="currentPage"
          :page-size="pageSize"
          :total="totalCount"
          layout="total, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </el-dialog>

</template>

<script>
import axios from 'axios';

export default {
  name: "App",
  created() {
    this.axiosInstance = axios.create({
      baseURL: this.serverUrl,
      withCredentials: true,
    });
    this.token = new URLSearchParams(window.location.search).get('token');
    if (this.token) {
      this.getUserData();
    } else {
      // 弹窗提示重新获取地址
      this.$message.warning("عنوان المحفظه غير صحيح");
    }
  },
  data() {
    return {
      tips: '',
      butStatus: -1,
      token: '',
      axiosInstance: null,
      // serverUrl: 'http://192.168.1.57:10100',
      serverUrl: 'https://game.gsbaloot.com',
      orderList: [], // 存放订单数据
      currentPage: 1, // 当前页
      pageSize: 10, // 每页显示的条数
      totalCount: 0, // 订单总数
      totalPage: 0,// 总页数
      product: {
        stock: 99999999,
        quantity: '',
        image: 'https://io.gsbaloot.com/game/101.jpg',
      },

      player: {
        playerId: "",
        playerAvatar: 'https://io.gsbaloot.com/game/0.png',
        playerDiamonds: 0, // 默认钻石数
        playerNickname: 'Player', // 默认昵称
        walletAddress: []// 玩家钱包地址
      },
      newWalletAddress: '',
      selectWallet: '',      // 用于存储用户输入的新钱包地址
      order: '',
      showWalletList: false,
      isEditing: false,
      isHeartbeatActive: false,
      isChongzhi: false,
      isWalletList: false,
      isOrderList: false,
      isTixian: false,
      isWalletCollapsed: false,
      walletAddressUrl: '',
      walletAddress: '',
      appUrl: [
        {
          name: 'Bitget',
          url: 'https://io.gsbaloot.com/game/bitget.png',
          link: 'bitget://open',
          appStoreLink: 'https://apps.apple.com/us/app/bitget/id1234567890'
        },
        {
          name: 'imToken',
          url: 'https://io.gsbaloot.com/game/imtoken.png',
          link: 'imtokenv2://navigate/AssetsTab/transfer',// imtokenv2://navigate/AssetsTab/transfer
          appStoreLink: 'https://apps.apple.com/us/app/imtoken/id1120344165'
        }, // 修正 imToken 的 App Store 链接
        {
          name: 'Bitpie',
          url: 'https://io.gsbaloot.com/game/bitpie.png',
          link: 'bitpie://open',
          appStoreLink: 'https://apps.apple.com/us/app/bitpie-wallet/id1123456789'
        },
        {
          name: 'OKEX',
          url: 'https://io.gsbaloot.com/game/okex.png',
          link: 'okx://wallet/dapp/url?dappUrl=',
          appStoreLink: 'https://apps.apple.com/us/app/okex/id1234567890'
        },
        {
          name: 'TokenPocket',
          url: 'https://io.gsbaloot.com/game/tokenpocket.png',
          link: 'tpoutside://pull.activity',
          appStoreLink: 'https://apps.apple.com/us/app/tokenpocket/id1234567890'
        },
        {
          name: 'TronLink',
          url: 'https://io.gsbaloot.com/game/tronlink.ico',
          link: 'tronlinkoutside://pull.activity',
          appStoreLink: 'https://apps.apple.com/us/app/tronlink-wallet/id1234567890'
        },
        {
          name: "Binance",
          url: 'https://io.gsbaloot.com/game/brand.png',
          link: 'tronlink://open',
          appStoreLink: 'https://apps.apple.com/us/app/tronlink-wallet/id1234567890'
        },
        {
          name: "CoinBase",
          url: 'https://io.gsbaloot.com/game/coinbase.ico',
          link: 'Coinbase://open',
          appStoreLink: 'https://apps.apple.com/us/app/tronlink-wallet/id1234567890'
        },
        {
          name: "MetaMask",
          url: 'https://io.gsbaloot.com/game/metamask.png',
          link: 'metamask://navigate/DappView?url=http://192.168.1.32:8848',
          appStoreLink: 'https://apps.apple.com/us/app/tronlink-wallet/id1234567890'
        },
        {
          name: "Trust",
          url: 'https://io.gsbaloot.com/game/trust.png',
          link: 'Trust://open',
          appStoreLink: 'https://apps.apple.com/us/app/tronlink-wallet/id1234567890'
        },
        {
          name: "Exodus",
          url: 'https://io.gsbaloot.com/game/exodus.ico',
          link: 'Exodus://open',
          appStoreLink: 'https://apps.apple.com/us/app/tronlink-wallet/id1234567890'
        },
        {
          name: "Blockchain",
          url: 'https://io.gsbaloot.com/game/Blockchain.ico',
          link: 'Blockchain://open',
          appStoreLink: 'https://apps.apple.com/us/app/tronlink-wallet/id1234567890'
        }
      ],
    }
  },
  methods: {
    getUserData() {
      this.axiosInstance.get("/80003", {
        params: {
          token: this.token
        },
      }).then(response => {
        console.log(response.data);
        if (response.data.code === 201) {
          // 更新用户数据
          this.player.playerId = response.data.body.playerId || this.player.playerId;
          this.player.playerAvatar = response.data.body.playerAvatar || this.player.playerAvatar;
          this.player.playerNickname = response.data.body.playerNickname || this.player.playerNickname;
          this.player.playerDiamonds = response.data.body.playerDiamonds || this.player.playerDiamonds;
          this.player.walletAddress = response.data.body.walletAddress || this.player.walletAddress;
        } else {
          this.$message.warning(response.data.body);
        }
      })
          .catch(error => {
            this.$message.error(error.message);
          });
    },
    openWallet(walletName) {
      const wallet = this.appUrl.find(app => app.name === walletName);
      if (wallet) {
        this.selectWallet = walletName;
        const link = wallet.link;

        // 尝试打开钱包应用
        const ua = navigator.userAgent;
        const isIOS = /iphone|ipad|ipod|ios/i.test(ua);
        const isAndroid = /android|XiaoMi|MiuiBrowser/i.test(ua);
        const isMobile = isIOS || isAndroid;

        if (isMobile) {
          window.location.href = link;
        }
      }
    },
    // 修改商品数量
    increaseQuantity(item) {
      if (!item.quantity) {
        item.quantity = 0;
      }
      if (item.quantity < item.stock) {
        item.quantity += 1;
        this.isHeartbeatActive = true;
        setTimeout(() => {
          this.isHeartbeatActive = false;
        }, 500);  // 动画持续500ms
      }
    },
    decreaseQuantity(item) {
      if (!item.quantity) {
        item.quantity = 0;
      }
      if (item.quantity > 1) {
        item.quantity -= 1;
      }
    },
    // 打开订单
    openOrder() {
      this.isOrderList = true;
      this.fetchOrders(); // 请求数据
    },
    fetchOrders() {
      this.axiosInstance.get("/80006", {
        params: {
          token: this.token,
          page: this.currentPage - 1,
          size: this.pageSize,
        },
      }).then(response => {
        if (response.data.code === 201) {
          console.log(response.data.body);
          console.log("this.orderList", this.orderList)
          this.orderList = response.data.body.data
          this.currentPage = response.data.body.currentPage + 1
          this.totalCount = response.data.body.totalElements
          this.totalPage = response.data.body.totalPages
        } else {
          this.$message.warning(response.data.body);
        }
      }).catch(error => {
        this.$message.error(error.message);
      });
    },
    // 分页变化时触发
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchOrders(); // 重新请求数据
    },
    // 格式化状态字段
    formatStatus(row) {
      if (row.payType === 'USDT') {
        return ['لم يتم الدفع', 'تم الدفع', 'تم الاستلام'][row.status] || 'null';
      } else if (row.payType === '101') {
        return ['تم تقديم طلب السحب', '', 'تم استلام السحب'][row.status] || 'null';
      }
      return '未知状态';
    },
    // 获取状态的颜色或类型
    getStatusStyle(row) {
      if (row.payType === 'USDT') {
        if (row.status === 0) return {backgroundColor: '#f5ee6c'}; // 未支付
        if (row.status === 1) return {backgroundColor: '#3a61c2'}; // 已支付
        if (row.status === 2) return {backgroundColor: '#67c23a'}; // 已支付已到账
      } else if (row.payType === '101') {
        if (row.status === 0) return {backgroundColor: '#f5ee6c'}; // 提款已提交
        if (row.status === 2) return {backgroundColor: '#67c23a'}; // 提款已到账
      }
      return {backgroundColor: '#909399'}; // 默认灰色：未知状态
    },
    // 获取状态的颜色或类型
    getStatusStyle2(type) {
      if (type === 'USDT') {
        return {backgroundColor: '#98c895'};
      } else if (type === '101') {
        return {backgroundColor: '#364171'}
      }
      return {backgroundColor: '#909399'}; // 默认灰色：未知状态
    },
    getProductyImageUrl(type) {
      if (type === "101") {
        return 'https://io.gsbaloot.com/game/101.jpg'
      } else if (type === "USDT") {
        return 'https://io.gsbaloot.com/game/usdtlogo.png'
      } else {
        return 'https://io.gsbaloot.com/game/avatar.png'
      }
    },
    formatOrderId(row, column, cellValue) {
      // 截取前5个字符，如果长度小于5则显示完整订单号
      return cellValue ? cellValue.slice(0, 5) + '...' : '';
    },
    sure2() {
      this.isTixian = false;

      this.axiosInstance.get("/80005", {
        params: {
          token: this.token,
          usdt_count: this.product.quantity,
          Wallet: this.selectWallet,
        },
      }).then(response => {
        console.log(response.data);
        if (response.data.code === 201) {
          // this.$message.info(response.data.body);
          this.order = response.data.body
          // this.isTixian = true;
          this.$message.success("تم الإرسال بنجاح");
        } else {
          this.$message.warning(response.data.body);
        }
      })
          .catch(error => {
            this.$message.error(error.message);
          });
    },
    // 打开购买确认弹窗
    sure() {
      if (this.butStatus === 1) {
        // 充值
        this.axiosInstance.get("/80004", {
          params: {
            token: this.token,
            usdt_count: this.product.quantity,
            Wallet: this.selectWallet,
          },
        }).then(response => {
          console.log(response.data);
          if (response.data.code === 201) {
            this.isChongzhi = true;
            this.order = response.data.body.order;
            this.walletAddress = response.data.body.walletAddress;
            this.walletAddressUrl = response.data.body.walletAddressUrl;
          } else {
            this.$message.warning(response.data.body);
          }
        })
            .catch(error => {
              this.$message.error(error.message);
            });
      } else if (this.butStatus === 2) {
        this.isTixian = true;
        // 提现

      }
    },
    // 重置弹窗内容
    resetDialog() {
      this.butStatus = -1;
      this.newWalletAddress = ''
      this.isWalletList = false;
      this.tips = ''
      this.isEditing = false;
      this.selectWallet = '';
      this.showWalletList = false;
    },
    orderClose() {
      this.isOrderList = false;
      this.currentPage = 1;
    },
    tixianClose() {
      this.isTixian = false;
    },
    // 打开钱包选择弹窗
    openWalletList(status) {
      this.butStatus = status
      this.isWalletList = true;
      this.selectWallet = this.player.walletAddress[0]; // 重置选中的钱包地址
      this.showWalletList = false; // 隐藏钱包地址列表
      this.tips = ''
    },
    // 清空选中的钱包地址
    clearWalletAddress() {
      this.selectWallet = ''; // 清空输入框内容
      this.tips = ''
      this.showWalletList = false; // 隐藏钱包地址列表
    },

    // 处理钱包地址选择
    onWalletSelect(selectedAddress) {
      if (selectedAddress === 'add_wallet') {
        this.addWallet(); // 如果选中的是“Add Wallet”，调用 addWallet 方法
      } else {
        console.log("====")
      }
    },

    // 触发添加钱包的逻辑
    addWallet() {
      this.tips = ''
      this.isEditing = true;  // 切换到编辑状态
      this.showWalletList = false;  // 关闭选择列表
    },
    // 当用户输入并离开输入框时，保存新钱包地址
    addNewWallet() {
      this.tips = ''
      this.axiosInstance.get("/80002", {
        params: {
          token: this.token,
          wallet: this.newWalletAddress
        },
      }).then(response => {
        console.log(response.data);
        if (response.data.code === 201) {
          if (!this.player.walletAddress.includes(response.data.body)) {
            this.player.walletAddress.push(response.data.body);
          }
          this.selectWallet = this.newWalletAddress;  // 设置选择的地址为新输入的地址
          this.newWalletAddress = '';  // 清空输入框
          this.isEditing = false;  // 退出编辑状态
          this.$message.success('تم إضافة عنوان المحفظة بنجاح');
          this.tips = ''
        } else {
          // this.$message.warning(response.data.body);
          this.tips = response.data.body;
        }
      })
          .catch(error => {
            this.$message.error(error.message);
          });
    },
    getPlayerAvatarUrl(avatar) {
      return avatar ? `https://io.gsbaloot.com/game/${avatar}.png` : 'https://io.gsbaloot.com/game/avatar.png'
    },
    copyWalletAddress() {
      if (navigator.clipboard) {
        // 使用 Clipboard API
        navigator.clipboard.writeText(this.walletAddress)
            .then(() => {
              this.$message.success('تم النسخ');
            })
            .catch(err => {
              this.$message.error('Failed to copy: ' + err);
            });
      } else {
        // 回退到 execCommand 方法
        const textArea = document.createElement('textarea');
        textArea.value = this.walletAddress;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          const successful = document.execCommand('copy');
          if (successful) {
            this.$message.success('تم النسخ');
          } else {
            this.$message.error('Failed to copy');
          }
        } catch (err) {
          this.$message.error('Failed to copy: ' + err);
        }
        document.body.removeChild(textArea);
      }
    }
  }
}
</script>

<style scoped>

.login {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('@/assets/background.png');
  background-size: cover; /* 让背景图完全覆盖 */
  background-position: center center;
  background-repeat: no-repeat;
}

.gem-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('@/assets/shop_bg2.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 10%;
  border-radius: 10px;

}

.diamond-icon {
  width: 80px;
  height: 90px;
  margin-bottom: 20px;
}

.buttons-container {
  display: flex;
  justify-content: space-between; /* 横向分布 */
  gap: 10px; /* 给按钮之间加一些间距 */
}


.quantity {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.quantity button {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
}

.quantity input {
  width: 100px; /* 增加宽度 */
  padding: 5px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin: 0 10px;
}

.avatar-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 新增的钱包图标样式 */
.wallet-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 10px;
}

.wallet-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  width: 45%;
}

.wallet-image {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 4px;
  margin-bottom: 5px;
}

.wallet-address-image {
  display: block;
  margin: 0 auto;
  width: 265px;
  height: 220px;
  object-fit: contain;
}

.wallet-item span {
  font-size: 12px;
  color: #333;
}

.wallet-item.selected {
  border: 2px solid #007bff;
  background-color: #e0f7ff;
}

.wallet-images {
  transition: max-height 0.3s ease-out;
  max-height: 1000px;
  overflow: hidden;
}

.wallet-images[style*="max-height: 0"] {
  max-height: 0;
}

.wallet-toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
  z-index: 10;
}

.player-id-container label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: #555;
}

.player-id-container input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.player-info {
  text-align: left;
  padding-left: 10px;
}

.arrow-icon {
  font-size: 20px; /* 确保字体大小合适 */
  color: #007bff; /* 确保箭头颜色与背景有足够对比 */
  margin-right: 10px; /* 给箭头添加一些右侧间距，确保它不被其他元素覆盖 */
}

.add-wallet-actions {
  display: flex;
  justify-content: center; /* 水平居中 */
  margin-top: 20px; /* 可选，增加按钮与上方输入框之间的间距 */
}

.dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 10px 0;
}

.total-pages {
  font-size: 14px;
  color: #888;
  margin-left: 20px;
}

.white-text {
  color: white;
  //font-size: 20px; /* 设置字体大小为 20px，你可以根据需要调整 */
}

.large-white-bold-text2 {
  font-size: 18px;
  color: white;
  font-weight: bold;
  padding-left: 50px; /* 缩进 */
  padding-right: 60px; /* 缩进 */
  padding-top: 15px; /* 缩进 */
  direction: rtl; /* 设置阿拉伯语的文本方向 */
}

.large-white-bold-text1 {
  font-size: 18px;
  color: white;
  font-weight: bold;
  padding-left: 50px; /* 缩进 */
  padding-right: 60px; /* 缩进 */
  padding-top: 0px; /* 缩进 */
  direction: rtl; /* 设置阿拉伯语的文本方向 */
}

.large-white-bold-text3 {
  font-size: 18px;
  color: white;
  font-weight: bold;
  padding-left: 50px; /* 缩进 */
  padding-right: 60px; /* 缩进 */
  padding-top: 15px; /* 缩进 */
  padding-bottom: 10px;
  direction: rtl; /* 设置阿拉伯语的文本方向 */
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* 调整按钮与其他内容之间的间距 */
}
</style>
